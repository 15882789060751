import DarkFooterSanchung from "components/Footers/DarkFooterSanchung.js";
import IndexHeader from "components/Headers/IndexHeader.js";
// reactstrap components
// import {
// } from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import React from "react";
import CarouselHerb from "./index-sections/CarouselHerb.js";
// import Carousel from "./index-sections/Carousel.js";
import CarouselPersimmon from "./index-sections/CarouselPersimmon.js";
import Contact from './index-sections/Contact';
import ImageHerb from './index-sections/ImagesHerb';
import ImagePersimmon from './index-sections/ImagesPersimmon';
import MarketLinks from './index-sections/MarketLinks';
import WordingHerb from './index-sections/WordingHerb';
import WordingPersimmon from './index-sections/WordingPersimmon';









function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          {
            // <ImagesMain />
          }
          <MarketLinks />
          <Contact />
          <ImagePersimmon />
          <WordingPersimmon />
          <CarouselPersimmon />

          <ImageHerb />
          <WordingHerb />
          <CarouselHerb />
          {/* 
          <ImageHoney />
          <WordingHoney />
          <CarouselHoney />
         */}

          <MarketLinks />
          <Contact />
          {
            // <BasicElements />
            // <Typography />
            // <Tabs />
            // <Pagination />
            // <Navbars />
            // <Notifications />
            // <Javascript />
            // <NucleoIcons />
            // <CompleteExamples />
            // <SignUp />
            // <Examples />
            // <Download />
          }

        </div>
        <DarkFooterSanchung />
      </div>
    </>
  );
}

export default Index;

