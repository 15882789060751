/*eslint-disable*/
import React from "react";
// reactstrap components
import { Container } from "reactstrap";


function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a
                href="https://farmkb.net"
                target="_blank"
              >
                지리산 시례골 곶감
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/persimmondry/"
                target="_blank"
              >
                Instagram
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Farmed by{" "}
          경봉 농원.
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
