/*eslint-disable*/
import React from "react";
// reactstrap components
import { Container } from "reactstrap";

// import Col from "reactstrap/lib/Col";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/main/main01.jpg") + ")"
            // backgroundImage: "url(" + require("assets/img/bg11.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          {
            // <div className="section-nucleo-icons">          
            // <Row>
            // <Col lg="6" md="12">
            //   <div className="icons-container">
            //   <i className="now-ui-icons ui-2_like"></i>
            //   </div>
            // </Col>
            // </Row>
            // </div>
          }
          <div className="content-center brand">
            {
              // (
              //   <img
              //   alt="..."
              //   className="n-logo"
              //   src={require("assets/img/now-logo.png")}
              //   ></img>
              // )
            }
            <h1 className="h1-seo">지리산 시례골 곶감</h1>
            <h3 className="h4-seo">경봉 농원</h3>

            <p></p>
            <h5>
              sanchung.kr 도메인 주소가 farmkb.net 으로 새롭게 바뀌었습니다.
            </h5>

          </div>

        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
