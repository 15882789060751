import React from "react";
// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";


// core components

function CompleteExamples() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">지리산 시례골 곶감</h2>
              <h5 className="description">
                저희 지리산 시례골 곶감은 지리산 시례골 산지에서 100% 직접 생산 및 배송을 합니다
              </h5>
              <h5 className="description">
                고객 여러분께 보다 양질의 상품을 저렴하게 구매하실수 있도록 최선을 다하겠습니다
              </h5>
            </Col>
          </Row>
          <Button
            className="btn-round"
            color="default"
            href="https://smartstore.naver.com/sirye/category/50002454?cp=1"
            outline
            target="_blank"
            size="lg"
          >
            네이버 스마트 스토어 곶감 코너
          </Button>
        </Container>

      </div>
    </>
  );
}

export default CompleteExamples;
