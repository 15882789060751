import React from "react";
// reactstrap components
import { Button, Container, Row } from "reactstrap";

// core components

function Examples() {
  return (
    <>
      <div className="section section-examples" data-background-color="black">
        <div className="space-50"></div>
        <Container className="text-center">
          <Row>
            <div className="col">
              <a href="https://smartstore.naver.com/sirye" target="_blank">
                <img
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/main/store2.png")}
                ></img>
              </a>
              <br></br>
              <Button
                className="btn-round"
                color="default"
                href="https://smartstore.naver.com/sirye"
                outline
                target="_blank"
                size="lg"
              >
                네이버 스마트 스토어 메인
              </Button>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Examples;
