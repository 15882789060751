import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components

function CompleteExamples() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">지리산 시례골 약초 나물</h2>
              <h5 className="description">
                지리산 시례골 산지에서 100% 직접 재배하는 약초 및 나물입니다
              </h5>
              <h5 className="description">
                고객 여러분께 보다 양질의 상품을 저렴하게 구매하실수 있도록 최선을 다하겠습니다
              </h5>
            </Col>
          </Row>
          <Button
            className="btn-round"
            color="default"
            href="https://smartstore.naver.com/sirye/category/50002461?cp=1"
            outline
            target="_blank"
            size="lg"
          >
            네이버 스마트 스토어 나물/약초 코너
          </Button>
        </Container>
      </div>
    </>
  );
}

export default CompleteExamples;
