import React from "react";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

// core components

function PaginationSection() {
  const [pills, setPills] = React.useState("2");
  
  return (
    <>
    
      <div className="section"  data-background-color="black">
      <div className="space"></div>
        <center>
        <h3>직거래 주문하기</h3>
        <Button
          className="btn-round"  color="link"
          href="tel:010-2798-0428">
          <img
            alt="..."
            className="img"
            src={require("assets/img/dial.png")}
            width="100px"
            height="100px"
          ></img>
        </Button>

        <h3>010-2798-0428</h3>
        </center>  
        <br></br>
        <div className="space"></div>
        
      </div>
      
    </>
  );
}

export default PaginationSection;
