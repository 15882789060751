import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Images() {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <center>
      
      <div id="images">
        {
          // <h4>Images</h4>
        }
        <Row>
        {
          // <Col sm="12">
          // <img
          // alt="..."
          // className=""
          // src={require("assets/img/main/graphic/persimmon01-small.jpeg")}
          // ></img>
          // </Col>
        }

          <Col sm="12">

            <img
              alt="..."
              className="img-raised"
              src={require("assets/img/main/graphic/herb01-small.jpeg")}
            ></img>
          </Col>

        </Row>
      </div>
      </center>
    </>
  );
}

export default Images;

// <div className="hero-images-container">
//   <img
//     alt="..."
//     src={require("assets/img/hero-image-1.png")}
//   ></img>
// </div>
// <div className="hero-images-container-1">
//   <img
//     alt="..."
//     src={require("assets/img/hero-image-2.png")}
//   ></img>
// </div>
// <div className="hero-images-container-2">
//   <img
//     alt="..."
//     src={require("assets/img/hero-image-3.png")}
//   ></img>
// </div>